<template>
    <div class="row">
        <div class="col-md-5">
            <div class="input-group">                                                
                <input type="number" class="form-control form-control-sm" id="intervalo-minimo-coste" placeholder="Min">
                <div class="input-group-append">
                    <span class="input-group-text form-control-sm">&euro;</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="input-group">                                                
                <input type="number" class="form-control form-control-sm" id="intervalo-maximo-coste" placeholder="Max">
                <div class="input-group-append">
                    <span class="input-group-text form-control-sm">&euro;</span>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <button class="btn btn-sm btn-primary">{{$t('general.anadir')}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <table class="table">
                <tbody>
                    <tr>
                        <td>100&euro; - 500&euro;</td>
                        <td><button type="button" class="btn btn-block btn-outline-danger btn-xs">{{$t('general.eliminar')}}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {

}
</script>